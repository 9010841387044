import React from "react"
import Wrapper from "../wrappers"
import styled from "styled-components"
import Layout from "../layouts/default"
import BlogList from '../components/blogList';

const Container = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 50px;
  margin: 50px auto;
  min-height: 60vh;
  @media only screen and (max-width:786px){
    padding: 20px;
  }
`

export default () => {
  return (
    <Layout>
      <Wrapper>
        <Container>
          <BlogList />
        </Container>
      </Wrapper>
    </Layout>
  )
}
