import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import BlogCard from '../blogCard';

const Container = styled.div``

export default ({ recent }) => {
  const {
    allContentfulBlogPost: { edges },
  } = useStaticQuery(graphql`
    {
      allContentfulBlogPost(sort: { fields: publishedAt, order: DESC }) {
        edges {
          node {
            title
            slug
            preview
            publishedAt(fromNow: true)
          }
        }
      }
    }
  `)

  return (
    <Container>
      {recent
        ? edges.map(({ node }, index) => index < 5 && <BlogCard node={node} />)
        : edges.map(({ node }) => <BlogCard node={node} />)}
    </Container>
  )
}
