import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  max-width: 800px;
  margin: auto;
  position: relative;
  height: 100%;
  width: 100%;
  
  @media only screen and (max-width: 768px){
    width: 95%;    
  }
`;

export default props => <Wrapper>{props.children}</Wrapper>
