import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const BlogLink = styled(Link)`
  text-decoration: none;
  font-size: 12px;
  color: ${props => props.theme.primaryColor};
`

const Title = styled.div`
  font-size: 25px;
  margin-bottom: 5px;
  color: ${props => props.theme.textDark};
  :hover {
    color: ${props => props.theme.primaryColor};
  }
  @media only screen and (max-width: 786px) {
    font-size: 17px;
  }
`

const BlogRow = styled.h3`
  margin-bottom: 10px;
`

const Date = styled.div`
  font-size: 14px;
  text-decoration: none;
  font-weight: 100;
  text-transform: capitalize;
  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
  @media only screen and (max-width: 786px) {
    font-size: 12px;
  }
`

const Preview = styled.div`
  font-size: 14px;
  font-weight: 100;
  font-style: italic;
  margin-top: 10px;
  @media only screen and (max-width: 786px) {
    font-size: 12px;
  }
`
export default ({ node }) => (
  <BlogRow>
    <BlogLink to={`/blog/${node.slug}`}>
      <Title>{node.title}</Title>
    </BlogLink>
    <Date>
      <svg class="svg-icon" viewBox="0 0 20 20">
        <path
          fill="none"
          d="M18.807,0.337h-3.616v1.808c0,0.475-0.384,0.859-0.859,0.859c-0.474,0-0.859-0.384-0.859-0.859V0.337H6.731
                              v1.808c0,0.475-0.384,0.859-0.859,0.859c-0.474,0-0.859-0.384-0.859-0.859V0.337h-3.82c-0.474,0-0.859,0.384-0.859,0.859v17.61
                              c0,0.477,0.384,0.859,0.859,0.859h17.613c0.474,0,0.859-0.382,0.859-0.859V1.195C19.665,0.721,19.281,0.337,18.807,0.337z
                               M17.948,17.946H2.052V4.528h15.896V17.946z"
        ></path>
      </svg>
      <span>{node.publishedAt}</span>
    </Date>
    <Preview>{node.preview}</Preview>
    <BlogLink to={`/blog/${node.slug}`}>Read More</BlogLink>
  </BlogRow>
)
